<template>
    <router-view />
    <footer>
        <img class="logo-eu" src="@/assets/mcul.svg" alt="Ministerio de Cultura. Gobierno de España." />
        <span class="text-mid">Actividad subvencionada por el Ministerio de Cultura.</span>
    </footer>
  </template>
  
  <script>
  export default {

  }
  </script>
  
  <style scoped>
  footer {
    position: fixed;
    bottom: 0;
    padding: 15px;
    width: 100%;
    background-color: rgb(17, 120, 178);
    display: flex;
    align-items: center;
  }
  .logo-eu {
    margin-right: 2em;
    max-height: 4em;
  }
  .text-mid {
    font-size: 10pt;
    color: white;
    margin-right: 2em;
    line-height: normal;
  }
  </style>