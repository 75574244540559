<template>

    <!-- Container -->
    <div class="sl-header">

        <!-- Header navbar -->
        <div class="navbar-viajeros inline-block">
            <img class="navbar-brand" alt="Smile and Learn" src="../assets/logo_sl.png">     
            <div class="dropdown">
                <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ getSelectedLanguageText() }}
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item hover-primary" @click="handleClick('ES')">Español (España)</a></li>
                    <li><a class="dropdown-item hover-primary" @click="handleClick('EN')">English</a></li>
                    <li><a class="dropdown-item hover-primary" @click="handleClick('LA')">Español (Latinoamérica)</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            selectedLanguage: 'ES',
        }
    },
    props: {
        // Whether this is the welcome screen.
        // In such case, hides the navbar button.
        welcome: Boolean,

        // Whether the button should be shown.
        showButton: Boolean,

        // API key (client token).
        apiKey: String,

        // API token.
        token: String,

        // Activity id.
        activityId: Number,

        // Language.
        lang: String,
    },

    methods: {
    handleClick(locale) {
      this.$i18n.locale = locale.toLowerCase();
      this.selectedLanguage = locale;
      this.$root.$emit('languageChanged', locale);
    },
    getSelectedLanguageText() {
            switch (this.selectedLanguage) {
                case 'ES':
                    return 'Español (España)';
                case 'EN':
                    return 'English';
                case 'LA':
                    return 'Español (Latinoamérica)';
                default:
                    return '';
            }
        }
  },
}
</script>
<style scoped>
.navbar-fixed-top {
    position: relative;
    z-index: 9999; 
}
.btn {
    margin-right: 7px;
}
.hover-primary:hover {
    background-color: rgb(17, 120, 178);
    color: white;
}
.custom-width {
    width: 100px; 
}
</style>