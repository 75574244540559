import { createWebHistory, createRouter } from "vue-router"

import HomeClasicos from "@/views/HomeClasicos.vue"

const routes = [

  // Start screen.
  // Allows the selection of the catalog.
  {
    path: "/",
    name: "HomeClasicos",
    component: HomeClasicos,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;