import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@/assets/css/styles.css'
import { BootstrapVue3 } from 'bootstrap-vue-3'
                     
import App from './App.vue'
import router from './router'
import i18n from '@/i18n'

const app = createApp(App)
const vfm = createVfm(app)
app.use(router)
app.use(i18n)
app.use(vfm)
app.use(BootstrapVue3)
app.mount('#app')
