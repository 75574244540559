<template>
  <div class="background">
    <!-- Header -->
    <smile-header :welcome="true" />

    <div class="contents-outer">
      <div class="contents-inner">

        <!-- Selector -->
        <div class="ribbon-title">
          <h1 class="title">{{ chosen ? this.$t(chosen) : this.$t('title') }}</h1>
        </div>

        <div v-if="chosen == null && url == null">
          <p class="choose">{{ this.$t('select') }}:</p>
          <div class="selector grid-3-1">
            <div class="option" @click="chosen = 'quixote'" :style="`background-image: url(${quijoteSrc}); cursor: pointer;`">
              <h2>{{ this.$t('quixote') }}</h2>
            </div>
            <div class="option" @click="chosen = 'lazarillo'" :style="`background-image: url(${lazarilloSrc}); cursor: pointer;`">
              <h2>{{ this.$t('lazarillo') }}</h2>
            </div>
            <div class="option" @click="chosen = 'platero'" :style="`background-image: url(${plateroSrc}); cursor: pointer;`">
              <h2>{{ this.$t('platero') }}</h2>
            </div>
          </div>
        </div>
        
        <div v-if="chosen != null && url == null">
          <div class="selector grid-3-1">
            <div class="option details">
              <img class="card-img-top" src="@/assets/video.png">
              <div class="card-body">
                <p class="card-text">{{ this.$t('video_description').replace('%TITLE%', this.$t(chosen)) }}</p>
                <a href="#" class="btn btn-go" @click="url = `https://assets.smileandlearn.com/Smile and Learn/AssetBundles/videos/${ids[chosen]}_VIDEO_${this.$i18n.locale.toUpperCase()}.mp4`">{{ this.$t('video_go') }}</a>
              </div>          
            </div>
            <div class="option details">
              <img class="card-img-top" src="@/assets/audiobook.png">
              <div class="card-body">
                <p class="card-text">{{ this.$t('audiobook_description').replace('%TITLE%', this.$t(chosen)) }}</p>
                <a href="#" class="btn btn-go" @click="url = `https://assets.smileandlearn.com/Smile and Learn/CustomContents/Audiobooks/${ids[chosen]}_AUDIOBOOK/${ids[chosen]}_AUDIOBOOK_${this.$i18n.locale.toUpperCase()}.mp3`">{{ this.$t('audiobook_go') }}</a>
              </div>          
            </div>
            <div class="option details">
              <img class="card-img-top" src="@/assets/tale.png">
              <div class="card-body">
                <p class="card-text">{{ this.$t('tale_description').replace('%TITLE%', this.$t(chosen)) }}</p>
                <a href="#" class="btn btn-go" @click="url = `https://assets.webgl.smileandlearn.com/${ids[chosen]}/index.html?l4ngs=${webglLang[this.$i18n.locale]}`">{{ this.$t('tale_go') }}</a>
              </div>          
            </div>
          </div> 
          <a href="#" class="btn btn-back" @click="chosen = null; url = null;">{{ this.$t('go_back') }}</a>
        </div>

        <div v-if="chosen != null && url != null">
          <div class="selector grid-1">
            <iframe ref="content" class="content" :src="url" allowfullscreen="true" scrolling="no"></iframe>
          </div>
          <a href="#" class="btn btn-full" @click="$refs['content'].requestFullscreen();">{{ this.$t('fullscreen') }}</a>
          <a href="#" class="btn btn-back" @click="url = null;">{{ this.$t('go_back') }}</a>
        </div>
      </div>
    </div>
  </div>
  <FooterClasicos />
</template>


<script>
import SmileHeader from '@/components/SmileHeader.vue'
import FooterClasicos from '@/components/FooterClasicos.vue'

export default {

  components: {
    SmileHeader,
    FooterClasicos
  },

  data() {
    return {
      chosen: null,
      url: null,
      ids: {
        quixote: 'DONQUIXOTE',
        platero: 'PLATEROANDI',
        lazarillo: 'LAZARILLOTORMES',
      },
      webglLang: {
        es: '3xnRPq+vDpbU1JHP7sr1/w==',
        en: 'CZlnmzl+Or62ruyOYUzTeQ==',
        la: 'NjcCdFsrSZpJAOXuu0srXA=='
      },
    }
  },
  
  computed: {
    quijoteSrc() {
      return require(`@/assets/quijote.png`);
    },
    lazarilloSrc() {
      return require(`@/assets/lazarillo.png`);
    },
    plateroSrc() {
      return require(`@/assets/platero.png`);
    },
  },
}
</script>


<style scoped>
.background {
  background-image: url('../assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
  width: 100vw;
  height: 100vh;
  padding-top: calc(100px + 4vh);
}

.contents-outer {
  margin: auto;
  background-image: url('../assets/book.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  text-align: center;
  height: 70vh;
  max-width: 90vw;
  aspect-ratio: 4/3; 
}

.ribbon-title {
  background-image: url('@/assets/banderola.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 50%;
  width: 100%;
  height: 10vh;
  margin-top: 0;
}

.title {
  font-size: 3vh;
  padding-top: 0.4em;
  color: #fff;
}

.choose {
  font-size: 2.4vh;
  margin-bottom: .5vh;
}

.selector {
  display: grid;
  padding: 0 2vh 0 2vh;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 45px;
}

.grid-3-1 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.grid-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.option {
  flex: 1;
  padding: 0 15px 15px 15px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  align-items: center;
  width: 100%;
  text-align: center;
  aspect-ratio: calc(241/374);
}

.option h2 {
  text-align: center;
  font-size: 2.5vh;
  margin: calc(120% + 15px) 1.5em 0 1.5em;
}

.option:hover {
  background-size: 70%;
}

.option:hover h2 {
  margin-left: 2.2em;
  margin-right: 2.2em;
  font-size: 2vh;
}

.card-img-top {
  width: 12vh;
  max-width: 6vw;
  aspect-ratio: 1;
}

.card-text {
  margin-top: 1em;
  padding: .3em;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 2vh;
  line-height: 1.5em;
  height: 22vh;  
}

.btn-go {
  width: 100%;
  height: 5vh;
  background-color: rgb(23,161,206);
  color: white;
  font-weight: 600;
  font-size: 2vh;
}

.btn-back {
  margin-left: 45px;
  width: 30%;
  height: 5vh;
  background-color: rgb(241,155,9);
  color: white;
  font-weight: 600;
  font-size: 2vh;
}

.btn-full {
  margin-left: 45px;
  width: 30%;
  height: 5vh;
  background-color: rgb(23,161,206);
  color: white;
  font-weight: 600;
  font-size: 2vh;
}

.content {
  margin-right: 45px;
  width: 90%;
  margin: 0em auto 1.5em auto;
  aspect-ratio: 16/9;
  overflow: hidden;
}

@media only screen and (max-width: 736px) {
  .background {
    height: 160vh;
  }

  .contents-outer {
    background: none;
    aspect-ratio: auto;
  }

  .title {
    font-size: 5vh;
  }

  .choose {
    font-size: 4vh;
  }

  .selector {
    margin: 0 auto 0 auto;
    width: 90%;
  }

  .option:hover {
    background-size: 70%;
  }

  .card-img-top {
    margin-top: 2em;
    width: 20vh;
    max-width: 10vw;
  }

  .card-text {
    background-color: rgba(255, 255, 255, 0.3);
    height: 36vh;
    font-size: 3.5vh;  
  }

  .option h2 {
    font-size: 3.5vh;
  }

  .option:hover h2 {
    font-size: 3vh;
  }

  .btn {
    height: 10vh;
    font-size: 4vh;
  }

  .btn-back {
    margin-left: 0;
  }

  .content {
    width: 100%;
  }
}

@media only screen and (max-width: 580px) {
  .background {
    height: 260vh;
    min-height: 300vw;
  }

  .title {
    font-size: 3.5vh;
  }

  .ribbon-title {
    background-size: 90%;
  }

  .grid-3-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .details {
    aspect-ratio: auto;
    margin-bottom: 2em !important;
  }

  .selector {
    margin: 0 auto 0 auto;
    width: 100%;
  }

  .option {
    margin: 0 auto 0 auto;
    width: 70%;
    margin-bottom: 0;
  }

  .option h2 {
    font-size: 4vh;
  }

  .option:hover h2 {
    font-size: 3vh;
  }

  .card-text {
    font-size: 2.5vh;
    height: 24vh;  
  }

  .card-img-top {
    width: 20vh;
    max-width: 50vw;
    aspect-ratio: 1;
  }

  .content {
    width: 100%;
  }

  .btn {
    font-size: 2vh;
    height: 5vh;
  }

  .btn-full {
    margin-left: 0;
    margin-right: 1em;
  }
}
</style>